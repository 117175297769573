import { ethers, BrowserProvider, JsonRpcSigner } from "ethers";
import type { Account, Chain, Client, Transport } from "viem";
import { useMemo } from "react";
import { useConnectorClient } from "wagmi";

export function clientToSigner(client: Client<Transport, Chain, Account>) {
  const { account, chain, transport } = client;

  if (!account || !account.address) {
    throw new Error("Invalid client or client account");
  }

  const network = {
    chainId: chain?.id ?? 0,
    name: chain?.name ?? "unknown",
    ensAddress: chain?.contracts?.ensRegistry?.address ?? undefined,
  };

  const provider = transport
    ? new BrowserProvider(transport, network)
    : new ethers.JsonRpcProvider(process.env.NEXT_PUBLIC_PROVIDER_URL);

  return new JsonRpcSigner(provider, account.address);
}

export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: client } = useConnectorClient({ chainId });

  return useMemo(() => (client ? clientToSigner(client) : undefined), [client]);
}
