"use client";

import { atom } from "jotai";
import { ethers } from "ethers";
import { getVotingPower } from "@/app/helpers/contracts/token/read";
import { hasVoted, canVote } from "@/app/helpers/contracts/governor/read";
import {
  proposalThresholdAtom,
  circulatingSupplyAtom,
} from "@/app/atoms/governorContract";
import { UserData } from "@/app/types/userData";

export const userDataAtom = atom<UserData>({
  address: ethers.ZeroAddress as `0x${string}`,
  delegateCount: 0,
  delegatesAddress: [],
  delegatorsAddresses: [],
  delegatorsCount: 0,
  isUserSelfDelegated: false,
  votingPower: "0",
  delegatedTokens: "0",
  meetsProposalThreshold: false,
  userHasVoted: false,
  canUserVote: false,
});

export const fetchUserDataAtom = atom(
  null,
  async (
    get,
    set,
    { address, proposalId }: { address?: `0x${string}`; proposalId?: number },
  ) => {
    const prevUserData = get(userDataAtom);
    const proposalThreshold = get(proposalThresholdAtom);
    const circulatingSupply = get(circulatingSupplyAtom);

    if (!address) {
      return;
    }

    try {
      const votingPower = await getVotingPower(address);
      let userHasVoted = false;
      let canUserVoteResult = false;
      let delegatedTokens = "0";

      if (proposalId) {
        userHasVoted = await hasVoted(proposalId, address);
        const canVoteResult = await canVote(proposalId, address);
        canUserVoteResult = canVoteResult.canVote;
        delegatedTokens = canVoteResult.delegatedTokens;
      }

      const proposalThresholdNumber = Number(
        proposalThreshold?.replace(/,/g, ""),
      );

      const cleanCirculatingSupply = Number(
        circulatingSupply?.replace(/,/g, ""),
      );

      const cleanVotingPower = Number(votingPower.replace(/,/g, ""));

      const meetsProposalThreshold =
        cleanVotingPower >= proposalThresholdNumber;

      const formattedDelegatedTokens = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Number(delegatedTokens));

      const updatedUserData = {
        ...prevUserData,
        address,
        votingPower,
        delegatedTokens: formattedDelegatedTokens,
        meetsProposalThreshold,
        userHasVoted,
        canUserVote: canUserVoteResult,
      };

      set(userDataAtom, updatedUserData);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  },
);
