"use client";

import { atom } from "jotai";
import { DelegateInfo } from "@/app/types/delegates";
import { getVotingPower } from "@/app/helpers/contracts/token/read";
import { userDataAtom } from "@/app/atoms/userData";
import { getTokenBalanceOf } from "@/app/helpers/contracts/token/read";
import { formatUnits } from "ethers";
import { abbreviateValue } from "@/app/helpers/abbreviateValue";

export const delegatesAtom = atom<DelegateInfo[]>([]);

export const delegatorsAtom = atom<{
  address: `0x${string}` | null;
}>({
  address: null,
});

async function GetVotingPower(account: string): Promise<string> {
  const votingPower = await getVotingPower(account);
  return votingPower;
}

async function getTokenBalance(address: `0x${string}`) {
  try {
    const { balance, decimals } = await getTokenBalanceOf(address);
    const rawBalance = parseFloat(formatUnits(balance, decimals));
    const formattedBalance =
      rawBalance >= 1_000_000
        ? abbreviateValue(rawBalance)
        : new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(rawBalance);
    return formattedBalance;
  } catch (error) {
    console.info("Something went wrong");
  }
}

const populateDelegates = async (get: any): Promise<DelegateInfo[]> => {
  const delegates = get(delegatesAtom) as DelegateInfo[];

  const updatedDelegates = await Promise.all(
    delegates.map(async (delegate) => {
      const votingPower = await GetVotingPower(delegate.address);
      const tokenBalance = await getTokenBalance(
        delegate.address as `0x${string}`,
      );
      const isUserSelfDelegated = delegate.delegators?.includes(
        delegate.address,
      );

      return {
        ...delegate,
        votingPower,
        tokenBalance,
        isUserSelfDelegated,
      } as DelegateInfo;
    }),
  );
  return updatedDelegates;
};

export const populatedDelegatesAtom = atom<Promise<DelegateInfo[]>>(
  async (get) => populateDelegates(get),
);

export const sortedDelegateAtom = atom<Promise<DelegateInfo[]>>(async (get) => {
  const populatedDelegates = await get(populatedDelegatesAtom);
  const userData = get(userDataAtom);
  const address = userData.address;

  return populatedDelegates.sort((a, b) => {
    const isAUserDelegate = a.delegators?.includes(address as `0x${string}`);
    const isBUserDelegate = b.delegators?.includes(address as `0x${string}`);

    if (isAUserDelegate && !isBUserDelegate) return -1;
    if (!isAUserDelegate && isBUserDelegate) return 1;
    return 0;
  });
});
