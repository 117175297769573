"use client";

import { ethers } from "ethers";
import { tokenABI } from "@/app/abi/tokenABI";
import { DelegateInfo, DelegateChangedEvent } from "@/app/types/delegates";
import { getVotingPower } from "@/app/helpers/contracts/token/read";

const tokenContractAddress = process.env
  .NEXT_PUBLIC_TOKEN_ADDRESS as `0x${string}`;

const provider = new ethers.JsonRpcProvider(
  process.env.NEXT_PUBLIC_PROVIDER_URL,
);

// Constants
const MAX_BLOCK_RANGE = 2000;
const RATE_LIMIT_DELAY = 500;
const PARALLEL_BLOCK_QUERIES = 10;
const PARALLEL_DELEGATE_QUERIES = 10;
const MAX_RETRIES = 10;
const MAX_BACKOFF = 64000;

const specificBlocks = (process.env.NEXT_PUBLIC_DELEGATES_SPECIFIC_BLOCKS || "")
  .split(",")
  .map(Number)
  .filter(Boolean);

const deploymentBlock = parseInt(
  process.env.NEXT_PUBLIC_TOKEN_DEPLOYMENT_BLOCK!,
  10,
);

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const exponentialBackoff = async (
  fn: Function,
  retries = MAX_RETRIES,
): Promise<any> => {
  try {
    return await fn();
  } catch (error) {
    if (retries === 0) throw error;
    const delayMs = Math.min(
      2 ** (MAX_RETRIES - retries) * 1000 + Math.random() * 1000,
      MAX_BACKOFF,
    );
    await delay(delayMs);
    return exponentialBackoff(fn, retries - 1);
  }
};

const fetchEvents = async (
  tokenContract: ethers.Contract,
  blocks: number[],
): Promise<ethers.Log[]> => {
  const blockBatches = [];
  const latestBlock = await provider.getBlockNumber();

  for (
    let startBlock = deploymentBlock;
    startBlock <= latestBlock;
    startBlock += MAX_BLOCK_RANGE
  ) {
    const endBlock = Math.min(startBlock + MAX_BLOCK_RANGE - 1, latestBlock);
    blockBatches.push([startBlock, endBlock]);
  }

  let allEvents: ethers.Log[] = [];

  for (let i = 0; i < blockBatches.length; i += PARALLEL_BLOCK_QUERIES) {
    const batchPromises = blockBatches
      .slice(i, i + PARALLEL_BLOCK_QUERIES)
      .map(async ([start, end]) => {
        const events = await exponentialBackoff(() =>
          tokenContract.queryFilter("DelegateChanged", start, end),
        );
        return events;
      });

    const batchEvents = await Promise.all(batchPromises);
    allEvents = allEvents.concat(...batchEvents);
    await delay(RATE_LIMIT_DELAY);
  }

  return allEvents;
};

const processEvents = (
  events: ethers.Log[],
  tokenContract: ethers.Contract,
) => {
  const delegatesMap = events.reduce(
    (acc, event) => {
      const parsedLog = tokenContract.interface.parseLog(event);
      const { delegator, fromDelegate, toDelegate } =
        parsedLog?.args as unknown as DelegateChangedEvent;
      if (toDelegate !== ethers.ZeroAddress) {
        acc[delegator] = { fromDelegate, toDelegate };
      } else {
        delete acc[delegator];
      }
      return acc;
    },
    {} as Record<string, { toDelegate: string; fromDelegate: string }>,
  );
  return delegatesMap;
};

const fetchBalancesAndCounts = async (
  addresses: string[],
  tokenContract: ethers.Contract,
  delegatesMap: Record<string, { toDelegate: string; fromDelegate: string }>,
): Promise<
  Record<
    string,
    {
      balance: string;
      count: number;
      votingPower: string;
      delegators: string[];
    }
  >
> => {
  const results: Record<
    string,
    {
      balance: string;
      count: number;
      votingPower: string;
      delegators: string[];
    }
  > = {};

  for (let i = 0; i < addresses.length; i += PARALLEL_DELEGATE_QUERIES) {
    const batchAddresses = addresses.slice(i, i + PARALLEL_DELEGATE_QUERIES);
    const batchResults = await Promise.all(
      batchAddresses.map(async (address) => {
        const balance = await tokenContract.balanceOf(address);
        const votingPower = await getVotingPower(address);

        const delegators = Object.entries(delegatesMap)
          .filter(([, value]) => value.toDelegate === address)
          .map(([key]) => key);

        return {
          address,
          balance: balance.toString(),
          count: delegators.length,
          votingPower: votingPower.toString(),
          delegators,
        };
      }),
    );

    batchResults.forEach((result) => {
      results[result.address] = result;
    });

    await delay(RATE_LIMIT_DELAY);
  }

  return results;
};

export const fetchDelegatesAndBalances = async (): Promise<DelegateInfo[]> => {
  const tokenContract = new ethers.Contract(
    tokenContractAddress,
    tokenABI,
    provider,
  );

  try {
    const allEvents = await fetchEvents(tokenContract, specificBlocks);

    const delegatesMap = processEvents(allEvents, tokenContract);

    const uniqueDelegates = Array.from(
      new Set(Object.values(delegatesMap).map((d) => d.toDelegate)),
    ) as `0x${string}`[];

    const delegateBalancesAndCounts = await fetchBalancesAndCounts(
      uniqueDelegates,
      tokenContract,
      delegatesMap,
    );

    const sortedDelegates = uniqueDelegates.sort((a, b) => {
      const blockA =
        allEvents.find((e) => {
          const parsedLog = tokenContract.interface.parseLog(e);
          return parsedLog?.args.toDelegate === a;
        })?.blockNumber || 0;

      const blockB =
        allEvents.find((e) => {
          const parsedLog = tokenContract.interface.parseLog(e);
          return parsedLog?.args.toDelegate === b;
        })?.blockNumber || 0;

      return blockB - blockA;
    });

    return sortedDelegates.map((address) => {
      const { balance, count, votingPower, delegators } =
        delegateBalancesAndCounts[address];

      return {
        address,
        tokenBalance: balance,
        delegationsReceived: count,
        votingPower,
        delegators,
      };
    });
  } catch (error) {
    console.error(`Error in fetchDelegatesAndBalances:`, error);
    throw error;
  }
};
